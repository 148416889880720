<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm
          ref="buyerRef"
          :fields="this.buyer.fields"
          :card="this.buyer.card"
          @change="buyerChange"
        >
          <template v-slot:footer>
            <b-button
              variant="primary"
              class="mt-4 float-right"
              @click="addNewBuyer"
              >Save</b-button
            >
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <ag-grid-table
          ref="grid-historico-buyers"
          :configUrl="historicoBuyersConfig"
          :dataUrl="historicoBuyersData"
          :editUrl="historicoBuyersEdit"
          :actions="actions"
        ></ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BButton

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

import useApiServices from '@/services/useApiServices.js';


import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  data()
  {
    return {

      actions: [
        { name: "Eliminar", icon: "Trash2Icon", action: this.actionEliminar },
      ],


      historicoBuyersConfig: useApiServices.historicoBuyersConfig,
      historicoBuyersData: useApiServices.historicoBuyersData,
      historicoBuyersEdit: useApiServices.historicoBuyersEdit,


      buyer: {
        card: {
          title: "Add New Buyer",
          subtitle: "",
          tooltip: ""
        },
        fields: [

          {
            label: "Nombre",
            type: "input",
            id: 'name',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Categoria",
            type: "input",
            id: 'categoria',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },
          {
            label: "Tier",
            type: "input",
            id: 'tier',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Brand",
            type: "input",
            id: 'brand',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

        
          {
            label: "Economic Interest Group",
            type: "input",
            id: 'economic_interest_group',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Merchandise Recipient Name",
            type: "input",
            id: 'merchandise_recipient_name',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



        ]
      },





    };
  },
  components: {
    BRow,
    BCol,
    AgGridTable,
    BrunaForm,
    BButton
  },

  directives: {
  },

  mounted()
  {

  },

  methods: {

    actionEliminar(params)
    {

      this.$bvModal.msgBoxConfirm('Confirma si quieres eliminar el registro', {
        title: 'Confirmar',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Eliminar',
        cancelTitle: 'Cancelar',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value =>
        {

          if (value)
          {
            useApiServices.postBuyersDelete(params.data)
              .then((response) =>
              {

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Eliminación Exitosa`,
                    icon: "UploadIcon",
                    variant: "success",
                    html: `El registro se ha eliminado.`,
                  },
                });

                this.$refs['grid-historico-buyers'].refreshRows([], false)


              })
              .catch((error) =>
              {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Ocurrió un Error`,
                    icon: "UploadIcon",
                    variant: "danger",
                    html: `Ha ocurrido un error</b>.`,
                  },
                });

              });


          }


        })
        .catch(err =>
        {
          // An error occurred
        })


    },

    addNewBuyer()
    {

      Promise.all([
        this.$refs.buyerRef.validate(),
      ]).then(([buyerValid]) =>
      {
        if (buyerValid)
        {

         let data = this.buyer.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})

          useApiServices.postBuyersCreate(data, this)
            .then((response) =>
            {

              this.$refs['grid-historico-buyers'].refreshRows()

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });

            })
            .catch((error) =>
            {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: `Ha ocurrido un error al crear el registro.`,
                },
              });

            });
        }
      });

    },

    buyerChange(data)
    {
    }

  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

